import React, { useState } from 'react';
import brand from '../images/brand.png';
import '../css/header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faDiscord } from '@fortawesome/free-brands-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive';
import ConnectButton from '../components/CustomConnectButton';
import { useContractRead, useContractWrite, usePrepareContractWrite } from 'wagmi';
import { useAccount } from 'wagmi';
import { useBalance } from 'wagmi';
import { ethers } from 'ethers';
import config from '../config/config.json';
import abiRewardManager from '../config/abiRewardManager.json';
import abiManager from '../config/abiManager.json';


export default function Nav() {
    const [isOpen, setIsOpen] = useState(false);
    const [isTabOpen, setIsTabOpen] = useState(false);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const [rewardEpochsNums, setRewardEpochsNums] = useState([]);

    // Get account

    const { address: userAddress, isConnecting, isDisconnected } = useAccount()

    // get balance

    const { data: balanceData, isError: isErrorBalance, isLoading: isLoadingBalance } = useBalance({
        address: userAddress,
    })

    // read contract

    const { data: rewardEpoch, isError: isErrorRewardEpoch, isLoadingRewardEpoch } = useContractRead({
        address: balanceData?.symbol === 'SGB' ? config.ADDR_SGB_MANAGER : config.ADDR_FLARE_MANAGER,
        abi: abiManager,
        functionName: 'getCurrentRewardEpoch',
        onSuccess: (data) => {
            let list = [];
            for (let i = 0; i <= data; i++) {
                list.push(i);
                // console.log(rewardEpochsNums);
            }
            setRewardEpochsNums(list);
            console.log(list);
        }

        
    })

    // prepare write contract
    const { config: configForClaim } = usePrepareContractWrite({
        address: balanceData?.symbol === 'SGB' ? config.ADDR_SGB_REWARD_MANAGER : config.ADDR_FLARE_REWARD_MANAGER,
        abi: abiRewardManager,
        functionName: 'claimReward',
        args: [userAddress, rewardEpochsNums],
    })


    // Write contract

    const { data: claimData, isLoading: isLoadingClaim, isSuccess: isSuccessClaim, write: writeClaim } = useContractWrite(configForClaim)

    const claim = () => {
        console.log("Claiming");
        writeClaim();
    }

    let menuItemsForMobile;
    if (isTabOpen) {
        menuItemsForMobile =
            <div className='menu-items-mobile'>
                <hr />
                <a className="btn" target={'_blank'} href="https://canarypunks.xyz/">
                    <img src={brand} alt="Canary Punks" />
                </a>
                <hr />
                <button className='wallet-connect' onClick={() => claim()}>Claim Rewards</button>
                <hr />
                <ConnectButton></ConnectButton>
                <hr />
                <a className="btn" target={'_blank'} href="https://stake.canarypunks.xyz/">
                    NFT Staking
                </a>
                <hr />
                {/* <a className="btn" target={'_blank'} href="https://punk-yield.vercel.app/">
                    Canary Staking
                </a> */}
                {/* <hr /> */}
                <div style={{ "paddingBottom": "1em" }}>
                    <a className="btn" target={'_blank'} href="https://discord.gg/AeHt6y353Y">
                        <FontAwesomeIcon className='awesome-icon' icon={faDiscord} />
                    </a>
                    <a className="btn" target={'_blank'} href="https://twitter.com/Canary_Punks">
                        <FontAwesomeIcon className='awesome-icon' icon={faTwitter} />
                    </a>
                </div>
            </div>
            ;
    }
    if (!isTabOpen) {
        menuItemsForMobile =
            <div className='menu-items-mobile hide'>
                <hr />
                <a className="btn" target={'_blank'} href="https://canarypunks.xyz/">
                    <img src={brand} alt="Canary Punks" />
                </a>
                <hr />
                <button className='wallet-connect' onClick={() => claim()}>Claim Rewards</button>
                <hr />
                <ConnectButton></ConnectButton>
                <hr />
                <a className="btn" target={'_blank'} href="https://stake.canarypunks.xyz/">
                    NFT Staking
                </a>
                <hr />
                {/* <a className="btn" target={'_blank'} href="https://punk-yield.vercel.app/">
                    Canary Staking
                </a>
                <hr /> */}
                <div>
                    <a className="btn" target={'_blank'} href="https://discord.gg/AeHt6y353Y">
                        <FontAwesomeIcon className='awesome-icon' icon={faDiscord} />
                    </a>
                    <a className="btn" target={'_blank'} href="https://twitter.com/Canary_Punks">
                        <FontAwesomeIcon className='awesome-icon' icon={faTwitter} />
                    </a>
                </div>
            </div>

            ;
    }
    if (isTabletOrMobile) {
        return (
            <nav className='mobile-nav header-nav'>
                <button className='btn' onClick={() => setIsTabOpen(!isTabOpen)}>
                    <FontAwesomeIcon className='awesome-icon' icon={faBars} />
                </button>
                {menuItemsForMobile}
            </nav>
        )
    }
    return (
        <nav className='header-nav'>
            <div className='left'>
                <a className="btn" target={'_blank'} href="https://canarypunks.xyz/">
                    <img src={brand} alt="Canary Punks" />
                </a>
                <a className="btn" target={'_blank'} href="https://stake.canarypunks.xyz/">
                    NFT Staking
                </a>
                {/* <a className="btn" target={'_blank'} href="https://punk-yield.vercel.app/">
                    Canary Staking
                </a> */}
            </div>
            <div className='right'>
                <a className="btn" target={'_blank'} href="https://twitter.com/Canary_Punks">
                    <FontAwesomeIcon className='awesome-icon' icon={faTwitter} />
                </a>
                <a className="btn" target={'_blank'} href="https://discord.gg/AeHt6y353Y">
                    <FontAwesomeIcon className='awesome-icon' icon={faDiscord} />
                </a>
                <button className='wallet-connect' onClick={() => claim()}>Claim Rewards</button>
                <ConnectButton ></ConnectButton>
                <span className='empty-space'></span>
            </div>
        </nav>
    )
}
