import React from 'react';
import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultWallets,
  RainbowKitProvider,
  darkTheme
} from '@rainbow-me/rainbowkit';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { Chain } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import Header from './Header';
import '../css/index.css';
import Footer from './Footer';
import Main from './Main';
import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import {
  injectedWallet,
  rainbowWallet,
  metaMaskWallet,
  coinbaseWallet,
  walletConnectWallet,

} from '@rainbow-me/rainbowkit/wallets';
import { bitfrost } from './bitfrostWallets.ts';


// Custom Chains

const songBird = {
  id: 19,
  name: 'Songbird Canary-Network',
  network: 'Songbird',
  iconUrl: 'https://blog.mexc.com/wp-content/uploads/2022/11/image-16-edited.png',
  iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: 'Songbird',
    symbol: 'SGB',
  },
  rpcUrls: {
    default: {
      http: ['https://songbird-api.flare.network/ext/C/rpc'],
    },
  },
  blockExplorers: {
    default: { name: 'songbird-explorer', url: 'https://songbird-explorer.flare.network/' },
  },
  testnet: false,
};

const flare = {
  id: 14,
  name: 'Flare Network',
  network: 'Flare',
  iconUrl: 'https://blog.mexc.com/wp-content/uploads/2022/11/image-16-edited.png',
  iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: 'Flare',
    symbol: 'FLR',
  },
  rpcUrls: {
    default: {
      http: ['https://rpc.flare.flr.finance/ext/bc/C/rpc'],
    },
  },
  blockExplorers: {
    default: { name: 'flare-explorer', url: 'https://flare-explorer.flare.network' },
  },
  testnet: false,
};


const { chains, provider } = configureChains(
  [songBird, flare],
  [
    publicProvider()
  ]
);

// Custom wallet

// export const bitFrost = ({ chains }) => ({
//   id: 'Bitfrost',
//   name: 'Bitfrost',
//   iconUrl: 'https://play-lh.googleusercontent.com/bGMzSI36aMMFJHVChMkke97D46pKifSy7f5N3zXxh4V41nXaDUAKfPVb3OVKQddrOME=w240-h480',
//   iconBackground: '#0c2f78',
//   downloadUrls: {
//     android: 'https://play.google.com/store/apps/details?id=com.bifrostwallet.app&hl=en_GB&gl=US&pli=1',
//     ios: 'https://apps.apple.com/gb/app/bifrost-wallet/id1577198351',
//   },
//   createConnector: () => {
//     const connector = getWalletConnectConnector({ chains });

//     return {
//       connector,
//       mobile: {
//         getUri: async () => {
//           const { uri } = (await connector.getProvider()).connector;
//           return uri;
//         },
//       },
//       desktop: {
//         getUri: async () => {
//           const { uri } = (await connector.getProvider()).connector;
//           return uri;
//         },
//       },
//     }
//   },
// });

const connectors = connectorsForWallets([
  {
    groupName: 'Suggested',
    wallets: [
      injectedWallet({ chains }),
      bitfrost({ chains }),
      rainbowWallet({ chains }),
      metaMaskWallet({ chains }),
      coinbaseWallet({ chains, appName: 'Punk-TSO' }),
      walletConnectWallet({ chains }),
    ],
  },
]);

// Wagmi Client

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
})

// Render

function App() {
  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains} theme={darkTheme()}>
        <div className='page'>
          <Header></Header>
          <Main></Main>
          <Footer></Footer>
        </div>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
