/* global BigInt */
import React from 'react';
import '../css/main.css';
import '../css/info.css';
import { useAccount } from 'wagmi'
import { useBalance } from 'wagmi'
import { useEffect } from 'react';
import { useContractRead } from 'wagmi'
import { useContractWrite, usePrepareContractWrite } from 'wagmi'
import { ethers } from 'ethers';
import config from '../config/config.json';
import abi from '../config/abi.json';
import abiRewardManager from '../config/abiRewardManager.json';
import abiManager from '../config/abiManager.json';
import { useContractReads } from 'wagmi';




export default function Main() {

  const [currentInteraction, setCurrentInteraction] = React.useState('Wrap');
  const [isWrapped, setIsWrapped] = React.useState(false);
  const [currentAmount, setCurrentAmount] = React.useState(0);
  const [balance, setBalance] = React.useState(0);
  const [balanceWrapped, setBalanceWrapped] = React.useState(0);
  const [delegatedPercentage, setDelegatedPercentage] = React.useState(0);
  const [delegatedPercentageToUs, setDelegatedPercentageToUs] = React.useState(0);
  const [symbol, setSymbol] = React.useState('?');
  const [reward, setReward] = React.useState(0);
  const [currentRewards, setCurrentRewards] = React.useState(0);
  const [prepareForStateOfRewards, setPrepareForStateOfRewards] = React.useState([]);

  let rewardEpochsNums = [];

  // Get account

  const { address: userAddress, isConnecting, isDisconnected } = useAccount()

  // Read balance

  const { data: balanceData, isError: isErrorBalance, isLoading: isLoadingBalance } = useBalance({
    address: userAddress,
  })
  const { data: balanceDataWSGB, isError: isErrorBalanceWSGB, isLoading: isLoadingBalanceWSGB } = useBalance({
    address: userAddress,
    token: balanceData?.symbol === 'SGB' ? config.ADDR_SGB : config.ADDR_FLARE,
  })

  // Read contract

  const { data: delegates, isError : isErrorDelegates, isLoadingDelegates } = useContractRead({
    address: balanceData?.symbol === 'SGB' ? config.ADDR_SGB : config.ADDR_FLARE,
    abi: abi,
    functionName: 'delegatesOf',
    args: [userAddress],
  })

  const {data: rewardEpoch, isError: isErrorRewardEpoch, isLoadingRewardEpoch} = useContractRead({
    address: balanceData?.symbol === 'SGB' ? config.ADDR_SGB_MANAGER : config.ADDR_FLARE_MANAGER,
    abi: abiManager,
    functionName: 'getCurrentRewardEpoch',
    onSuccess: (data) => {
      let list = []
      for (let i = 0; i <= data; i++) {
        rewardEpochsNums.push(i);
        // console.log(rewardEpochsNums);
        list.push({
          address: balanceData?.symbol === 'SGB' ? config.ADDR_SGB_REWARD_MANAGER : config.ADDR_FLARE_REWARD_MANAGER,
          abi: abiRewardManager,
          functionName: 'getStateOfRewards',
          args: [userAddress, i],
        })
      }
      setPrepareForStateOfRewards(list);
    }
  })

  const {data: stateOfRewards, isError: isErrorStateOfRewards, isLoadingStateOfRewards} = useContractReads({
   contracts: prepareForStateOfRewards,
  })


  // Prepare contract write

  const { config: configForDelegate } = usePrepareContractWrite(currentInteraction == 'Delegate' ? {
    address: balanceData?.symbol === 'SGB' ? config.ADDR_SGB : config.ADDR_FLARE,
    abi: abi,
    functionName: 'delegate',
    args: [balanceData?.symbol === 'SGB' ? config.FTSO_SGB : config.FTSO_FLARE, (currentAmount * 100).toString()],
  } : { })

  const {config: configForWrap} = usePrepareContractWrite(currentInteraction == 'Wrap' ? {
    address: balanceData?.symbol === 'SGB' ? config.ADDR_SGB : config.ADDR_FLARE,
    abi: abi,
    functionName: 'deposit',
    overrides: {
      from: userAddress,
      value: (() => {
        const currentAmountString = parseInt(currentAmount).toString();
        // if (!Number.isFinite(currentAmount)) {
        //   throw new Error("currentAmount is not a valid number, it's: " + currentAmount + " (" + typeof currentAmount + ")");
        // }
        return (BigInt(currentAmountString.type == Number ? currentAmountString : "0") * BigInt("1000000000000000000")).toString();
      })(),
    },
  } : { })

  const {config: configForUnwrap} = usePrepareContractWrite( currentInteraction == 'Unwrap' ?{
    address: balanceData?.symbol === 'SGB' ? config.ADDR_SGB : config.ADDR_FLARE,
    abi: abi,
    functionName: 'withdraw',
    args: [(BigInt(currentAmount) * BigInt("1000000000000000000")).toString()],
  } : { })
  

  // Write contract

  const { data : delegateData, isLoading : isLoadingDelegate, isSuccess : isSuccessDelegate, write : writeDelegate  } = useContractWrite(configForDelegate)
  
  const { data : wrapData, isLoading : isLoadingWrap, isSuccess : isSuccessWrap, write : writeWrap  } = useContractWrite(configForWrap)

  const { data : unwrapData, isLoading : isLoadingUnwrap, isSuccess : isSuccessUnwrap, write : writeUnwrap  } = useContractWrite(configForUnwrap)

  // Functions

  
  const writeData = () => {
    if (currentInteraction == 'Wrap') {
      writeWrap();
    }
    if (currentInteraction == 'Unwrap') {
      writeUnwrap();
    }
    if (currentInteraction == 'Delegate') {
      writeDelegate();
    }
  }

  const getBalance = () => {
    let res = [];
    if (isLoadingBalance) res = ["0", "?"];
    if (isErrorBalance) res = ["0", "?"];
    if (balanceData) res = [balanceData?.value.toString(), balanceData?.symbol];
    if(res[0] == undefined) res = ["0", "?"];
    setBalance((BigInt(res[0]) / 1000000000000000000n));
    if (isLoadingBalanceWSGB) res = ["0", "?"];
    if (isErrorBalanceWSGB) res = ["0", "?"];
    if (balanceDataWSGB) res = [balanceDataWSGB?.value.toString(), balanceData?.symbol];
    if(res[0] == undefined) res = ["0", "?"];
    setBalanceWrapped((BigInt(res[0]) / 1000000000000000000n));
    setSymbol(res[1]);
  }
  
  const getDelegatedPercentages = () => {
    let res = 0;
    if (isLoadingDelegates) res = 0;
    if (isErrorDelegates) res = 0;
    if (delegates)
    {

      for (let i = 0; i < delegates?._count; i++) {
        if(delegates?._delegateAddresses[i] == (balanceData?.symbol === 'SGB' ? config.FTSO_SGB : config.FTSO_FLARE)) {
          setDelegatedPercentageToUs(delegates?._bips[i] / 100);
        }
        res += delegates?._bips[i] / 100;
      }
    }
    setDelegatedPercentage(res);
  }

  const getReward = () => {
    let res = [0, 0];
    if (isLoadingStateOfRewards) res = [0,0];
    if (isErrorStateOfRewards) res = [0,0];
    if (stateOfRewards) 
    {
     stateOfRewards.forEach((reward) => {
      for(let i = 0; i < reward?._rewardAmounts.length; i++) {
        if(reward?._claimed[i] == false && reward?._claimable == true)
        {
          res[0] += reward?._rewardAmounts[i] / 1000000000000000000;
        }
        if(reward?._claimable == false)
        res[1] += reward?._rewardAmounts[i] / 1000000000000000000;
      }
      })
    }
    setReward(res[0]);
    setCurrentRewards(res[1]);
  }
    
  // Effects

  useEffect(() => {
    getBalance();
  }, [balanceData, isLoadingBalance, isErrorBalance])

  useEffect(() => {
    getDelegatedPercentages();
  }, [delegates, isLoadingDelegates, isErrorDelegates])

  useEffect(() => {
    getReward();
  }, [stateOfRewards, isLoadingStateOfRewards, isErrorStateOfRewards])

  useEffect(() => {
    setCurrentAmount(0);
    getBalance();
    getDelegatedPercentages();
    getReward();
  }, [currentInteraction])

  // useEffect(() => {
  //   if (!Number.isFinite(currentAmount)) {
  //     throw new Error("currentAmount is not a valid number");
  //   }
  //   if (currentAmount < 0) {
  //     throw new Error("currentAmount is negative");
  //   }
  // }, [currentAmount, balance]);

  // Render

  if (isConnecting) return <main className='unconnected'><h1>Connecting...</h1></main>
  if (isDisconnected) return <main className='unconnected'><h1>Please connect your wallet</h1></main>
  if (!userAddress) return <main className='unconnected'><h1>Please connect your wallet</h1></main>


  return (
    <main>
      <div className='stats-display'>
        <div className='stat-block'>
          <p>{delegates?._count.toString()}</p>
          <p>Active delegations</p>
        </div>
        <div className='stat-block'>
          <p>{delegatedPercentageToUs}%</p>
          <p>Delegated to Canary Punks</p>
        </div>
        <div className='stat-block'>
          <p>{reward.toFixed(2)}</p>
          <p>Claimable rewards</p>
        </div>
        <div className='stat-block'>
          <p>{currentRewards.toFixed(2)}</p>
          <p>Current epoch rewards</p>
        </div>
      </div>
      <div className='interaction-window'>
        <nav className='option-select'>
          <button onClick={() => {setCurrentAmount(0); setCurrentInteraction("Wrap"); setIsWrapped(false)}} disabled={currentInteraction == "Wrap"}>Wrap</button>
          <button onClick={() => {setCurrentAmount(0); setCurrentInteraction("Unwrap"); setIsWrapped(true)}} disabled={currentInteraction == "Unwrap"}>Unwrap</button>
          <button onClick={() => {setCurrentAmount(0); setCurrentInteraction("Delegate"); setIsWrapped(true)}} disabled={currentInteraction == "Delegate"}>Delegate</button>
        </nav>
        <hr></hr>
        { currentInteraction == "Delegate" ?
        <>
        <div className='details'>
          <p>{currentInteraction}</p>
          <p>{100 - delegatedPercentage + delegatedPercentageToUs}%</p>
        </div>
        <input type="number" className='num-box' value={currentAmount} onChange={(e) => { setCurrentAmount(e.target.value) }} min="0" max={100 - delegatedPercentage + delegatedPercentageToUs}></input>
        <input type="range" className='slider' value={currentAmount} onChange={(e) => { setCurrentAmount(e.target.value) }} min="0" max={100 - delegatedPercentage + delegatedPercentageToUs} step="0.1"></input>
        <button onClick={() => writeData()}>{currentInteraction} {currentAmount}% of {isWrapped ? "W" : ""}{symbol}</button>
        </>
        :
        <>
        <div className='details'>
          <p>{currentInteraction}</p>
          <p>{isWrapped ? balanceWrapped.toString() : balance.toString()} {isWrapped ? "W" : ""}{symbol}</p>
        </div>
        <input type="number" className='num-box' value={currentAmount} onChange={(e) => { setCurrentAmount(e.target.value) }} min="0" max={Math.trunc(isWrapped ? balanceWrapped.toString() : balance.toString())}></input>
        <input type="range" className='slider' value={currentAmount} onChange={(e) => { setCurrentAmount(e.target.value) }} min="0" max={Math.trunc(isWrapped ? balanceWrapped.toString() : balance.toString())}></input>
        <button disabled={currentAmount == 0} onClick={() => writeData()}>{currentInteraction} {currentAmount} {isWrapped ? "W" : ""}{symbol}</button>
        </>
        }
        
      </div>
    </main>
  )
}
