import {
    Chain,
    Wallet,
} from '@rainbow-me/rainbowkit';

import { InjectedConnector } from "wagmi/connectors/injected";
import bitFrostLogo from '../images/bitfrost.jpg';
export interface MyWalletOptions {
    chains: Chain[];
}
export const bitfrost = ({ chains }: MyWalletOptions): Wallet => ({
    id: 'bitfrost',
    name: 'Bitfrost',
    iconUrl: bitFrostLogo,
    iconBackground: '#0c2f78',
    downloadUrls: {
        android: 'https://bifrostwallet.com/',
        ios: 'https://bifrostwallet.com/',
        qrCode: 'https://bifrostwallet.com/',
    },
    createConnector: () => {
        const connector = new InjectedConnector({
            chains,
            options: { shimDisconnect : true}
          })
        return {
            connector,
            mobile: {
                getUri: async () => {
                    const { uri } = (await connector.getProvider()).connector;
                    return uri;
                },
            },
            qrCode: {
                getUri: async () =>
                    (await connector.getProvider()).connector.uri,
                instructions: {
                    learnMoreUrl: 'https://bifrostwallet.com/',
                    steps: [
                        {
                            description:
                                'We recommend putting My Wallet on your home screen for faster access to your wallet.',
                            step: 'install',
                            title: 'Open the My Wallet app',
                        },
                        {
                            description:
                                'After you scan, a connection prompt will appear for you to connect your wallet.',
                            step: 'scan',
                            title: 'Tap the scan button',
                        },
                    ],
                },
            },
        };
    },
});
